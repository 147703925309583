import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

const setupFlatpickr = () => {
  document.querySelectorAll(".flatpickr-range").forEach(function (selector) {
    const bookedPeriods = JSON.parse(selector.dataset.bookedPeriods || "[]");
    const onlyDeparturePossibleDates = bookedPeriods.map((period) => {
      return period[0];
    });

    const pickrInstance = flatpickr(selector, {
      allowInput: false,
      locale: "fr",
      mode: "range",
      showMonths: document.body.getBoundingClientRect().width > 620 ? 2 : 1,
      minDate: selector.dataset.minDate || null,

      onDayCreate: function (dObj, dStr, fp, dayElem) {
        if (
          onlyDeparturePossibleDates.includes(
            flatpickr.formatDate(dayElem.dateObj, "Y-m-d")
          )
        ) {
          // only add no-start class if we're choosing a arrival date
          // it's the only case where it makes sense to show a warning about
          // this day being only available for departures
          if (fp.selectedDates.length == 0) {
            dayElem.classList.add("no-start");
          }
        }
      },

      onChange: function (selectedDates, dateStr, instance) {
        document.querySelector(`#${selector.dataset.idStartDate}`).value =
          selectedDates[0];
        document.querySelector(`#${selector.dataset.idEndDate}`).value =
          selectedDates[selectedDates.length - 1];
      },

      defaultDate: selector.dataset.initialValue
        ? selector.dataset.initialValue.split(",")
        : "",
      dateFormat: "d-m-Y",
    });

    const clearIcon = selector.parentElement.querySelector("#clear-icon");

    if (clearIcon) {
      clearIcon.addEventListener("click", (e) => {
        pickrInstance.clear();
        pickrInstance.open();
      });
    }
  });
};

// Listen to multiple turbo event types
// https://github.com/hotwired/turbo/issues/85
// One downside is that it might fire multiple times, with unintended side effects
// From testing though, it seems to work fine.

// This is fired when a form is re-rendered after a 422 error for example
document.addEventListener("turbo:render", function (event) {
  setupFlatpickr();
});

document.addEventListener("turbo:load", function (event) {
  setupFlatpickr();
});
